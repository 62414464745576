@tailwind base;
@tailwind components;
@tailwind utilities;

.body{
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  @apply bg-black;
}
.container-white{
  @apply container px-8 mx-auto bg-black;
}
.container-dark{
  @apply dark container mx-auto px-8;
}
